import { AppBar, Typography } from "@mui/material";

const FooterBar = () => {
  return (
    <>
      <AppBar
        position="fixed"
        sx={{ top: "auto", bottom: 0, backgroundColor: "#fcf0d0" }}
      >
        <Typography
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            marginTop: "10px",
            color: "#12265a",
            fontWeight: "900",
          }}
        >
          Le foto prodotto sono esclusivamente a scopo dimostrativo, i colori
          degli articoli nelle foto potrebbero essere differenti da quanto
          scelto.
        </Typography>
      </AppBar>
    </>
  );
};

export default FooterBar;
