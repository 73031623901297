import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { APICodes } from "../../Components/API";

const BackgroundChanger = () => {
  const [backgroundImage, setBackgroundImage] = useState("");
  const [mediaIndex, setMediaIndex] = useState(0);
  const [mediaList, setMediaList] = useState([]);
  const navigate = useNavigate();
  const Ref = useRef(null);

  const fetchMediaList = () => {
    fetch(APICodes.MEDIA)
      .then((data) => data.json())
      .then((data) => {
        setMediaList(data.media);
      })
      .catch((err) => {
        console.error("Error fetching media list from API: " + err);
      });
  };

  const iniTimer = () => {
    if (Ref.current) clearInterval(Ref.current);

    const intervalId = setInterval(() => {
      setMediaIndex((prevIndex) => (prevIndex + 1) % mediaList.length);
    }, 15000);

    Ref.current = intervalId;
  };

  useEffect(() => {
    fetchMediaList();
  }, []);

  useEffect(() => {
    if (mediaList.length > 0) {
      setBackgroundImage(APICodes.MEDIA + "/" + mediaList[mediaIndex].name);
      iniTimer();
    }
  }, [mediaIndex, mediaList]);

  useEffect(() => {
    return () => clearInterval(Ref.current);
  }, []);

  const handlePageClick = () => {
    navigate("/ricerca");
  };

  const style = {
    backgroundColor: "black",
    backgroundImage: `url('${backgroundImage}')`,
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    transition: "background-image 1s ease-in-out",
    height: "100vh",
  };

  return <div style={style} onClick={handlePageClick}></div>;
};

export default BackgroundChanger;
