import {
  AppBar,
  Box,
  Button,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { useMediaQuery } from "@mui/material";
import NomeBar from "./NomeBar";
import Badge from "@mui/material/Badge";
import { useSessionStorage } from "../hooks/UseSessionStorage";
import LogOut from "../Pagine/LoginPage/LogOut";
import { UserContext } from "../App";

const NavBar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [tipoUtente, setTipoUtente] = useSessionStorage("tipo", undefined);
  const [cassa, setCassa] = useSessionStorage("cassa", false);
  const [totem, setTotem] = useSessionStorage("totem", false);
  const { cartCount, setCartCount } = useContext(UserContext);
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const isMobile = useMediaQuery("(max-width:500px)");

  function coloreNavBar() {
    if (cassa) {
      return { backgroundColor:'white'};
    } else {
      return { backgroundColor: "#fcf0d9", color: "#12265a" };
    }
  }

  function doLogOut(){
    LogOut(tipoUtente==="P")
  }
  return (
    <>
      <AppBar position="fixed" sx={coloreNavBar()}>
        <NomeBar />
        {!totem && (
          <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h2">
              <img
                src="/assets/img/Qlogo-removebg-preview.png"
                alt="Logo_Quattroelle"
                style={{
                  height: isMobile ? "60px" : "110px",
                  marginTop: "10px",
                }}
              />
            </Typography>

            <Box>
              <Hidden smDown>
                <Button
                  component={Link}
                  to="/lista"
                  color="inherit"
                  sx={{
                    color: "#12265a",
                    textTransform: "none",
                    fontSize: "30px",
                    marginRight: "15px",
                    fontWeight: "bold",
                    fontFamily: "Nunito",
                  }}
                >
                  Lista
                </Button>

                {tipoUtente === "P" ? (
                  <Badge badgeContent={cartCount} color="primary">
                    <Button
                      component={Link}
                      to="/carrello"
                      color="inherit"
                      sx={{
                        color: "#12265a",
                        textTransform: "none",
                        fontSize: "30px",
                        fontWeight: "bold",
                        fontFamily: "Nunito",
                      }}
                    >
                      Carrello
                    </Button>
                  </Badge>
                ) : (
                  <Button
                    component={Link}
                    to="/dediche"
                    color="inherit"
                    sx={{
                      color: "#12265a",
                      textTransform: "none",
                      fontSize: "30px",
                      marginRight: "15px",
                      fontWeight: "bold",
                      fontFamily: "Nunito",
                    }}
                  >
                    Dediche
                  </Button>
                )}

                <Button
                  component={Link}
                  onClick={doLogOut}
                  color="inherit"
                  sx={{
                    color: "#12265a",
                    textTransform: "none",
                    fontSize: "30px",
                    marginLeft: "15px",
                    fontWeight: "bold",
                    fontFamily: "Nunito",
                  }}
                >
                  Esci
                </Button>
              </Hidden>
              <Hidden smUp>
                <IconButton color="inherit" onClick={toggleDrawer}>
                  <MenuIcon sx={{ color: "#12265a" }} />
                </IconButton>
              </Hidden>
            </Box>
          </Toolbar>
        )}
      </AppBar>
      <Drawer anchor="bottom" open={drawerOpen} onClose={toggleDrawer}>
        <List>
          <ListItem button component={Link} to="/lista">
            <ListItemText primary="Lista" />
          </ListItem>
          {tipoUtente === "P" ? (
            <ListItem button component={Link} to="/carrello">
              <Badge badgeContent={cartCount} color="primary">
                <ListItemText primary="Carrello" />
              </Badge>
            </ListItem>
          ) : (
            <ListItem component={Link} button to="/Dediche">
              <ListItemText primary="Dediche" />
            </ListItem>
          )}

          <ListItem button component={Link}  onClick={doLogOut}>
            <ListItemText primary="Esci" />
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};

export default NavBar;
