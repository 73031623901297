import React, { useEffect, useState } from "react";
import CardArticoloAcquisto from "./CardArticoloAcquisto";
import CardArticoloGenitori from "./CardArticoloGenitori";
import NavBar from "../../Components/NavBar";
import {
  Box,
  Grid,
  Typography,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import FooterBar from "../../Components/FooterBar";
import { useSessionStorage } from "../../hooks/UseSessionStorage";
import { APICodes } from "../../Components/API";


const Lista = () => {
  const [products, setProducts] = useState([]);
  const [tipoUtente, setTipoUtente] = useSessionStorage("tipo", undefined);
  const [token, setToken] = useSessionStorage("token", undefined);
  const [nomeBambino, setNomeBambino] = useSessionStorage("nome", "");
  const [totem, setTotem] = useSessionStorage("totem", false);
  const [loading, setLoading] = useState(true);
  const fetchLista = () => {

    setNomeBambino('');
    setLoading(true);
    setProducts([]);
    const options = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    fetch(APICodes.LISTE, options)
      .then((response) => {
        setLoading(false);
        return response.json();
      })
      .then((response) => {
        setProducts(response.articoli);
        setNomeBambino(response.lista.f_nome);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  useEffect(() => {
    fetchLista();
  }, []);

  function selettore() {
    if (totem) {
      console.log(totem);
      return "divContainerToten";
    } else {
      return "divContainer";
    }
  }

  return (
    <div className={selettore()}>
      <NavBar />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <Typography
          variant="h4"
          className="Titulo"
          style={{fontFamily: 'Nunito', marginTop:'50px', color:'#12265a'}}
        >
          Articoli desiderati
        </Typography>
      </Box>
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: { xs: "15px ", sm: "50px" },
        }}
      >
        <Grid xs={12} sm={10} md={10} xl={10}>
          {products.map((product, index) => (
            <>
              {tipoUtente === "P" ? (
                <CardArticoloAcquisto
                  OnAggiungiAlCarrello={() => {
                    product.acquisto = 1;
                    product.carrello = 1;
                  }}
                  key={index}
                  product={product}
                />
              ) : (
                <CardArticoloGenitori key={index} product={product} />
              )}
            </>
          ))}
        </Grid>
      </Grid>
      <FooterBar />
    </div>
  );
};

export default Lista;
