import React, { useEffect, useState } from "react";
import NavBar from "../../Components/NavBar";
import CardDedica from "./CardDedica";
import { Box, Grid, Typography } from "@mui/material";
import { useSessionStorage } from "../../hooks/UseSessionStorage";
import { APICodes } from "../../Components/API";

const Dediche = () => {
  const [dediche, setDedica] = useState([]);
  const [token, setToken] = useSessionStorage("token", undefined);
  const currentYear = new Date().getFullYear();
  const FetchData = () => {
    const options = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    fetch(APICodes.DEDICHE, options)
      .then((response) => response.json())
      .then((data) => {
        setDedica(data.dediche);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    FetchData();
  }, []);

  return (
    <>
      <NavBar />

      <div className="containerPrinc">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "10px",
            marginTop: "120px",
          }}
        >
          <Typography
            variant="h4"
            style={{ color: "#12265a", fontWeight: "900", marginTop: "50px" }}
          >
            Vedi l'elenco delle tue dediche
          </Typography>
        </Box>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          {dediche.map((dedica, index) => (
            <CardDedica key={index} dedica={dedica} />
          ))}
        </Grid>
      </div>
    </>
  );
};

export default Dediche;
