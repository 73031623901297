import { Card, CardContent, Typography } from "@mui/material";

const ListaBimbi = ({ bambino, ApriLista }) => {
  return (
    <>
      <Card
        style={{
          marginTop: "50px",
          padding: "10px",
          width: "300px",
          boxShadow: "none",
        }}
        className="cardBimbi"
      >
        <CardContent>
          <Typography
            variant="h6"
            component="div"
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "10px",
            }}
          >
            {bambino.bambino}
          </Typography>

          <Typography
            variant="body2"
            color="text.secondary"
            style={{ fontWeight: "900", fontSize: "15px" }}
          >
            Nome del Padre: {bambino.papa}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            style={{ fontWeight: "900", fontSize: "15px" }}
          >
            Nome della madre: {bambino.mamma}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Data Battesimo: {bambino.data_evento || "N/A"}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Data Nascita: {bambino.data_nascita}
          </Typography>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <div
              className="divBtn"
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <button className="btnCard" onClick={ApriLista}>
                Apri Lista
              </button>
            </div>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default ListaBimbi;
