import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import ListaBimbi from "./ListaBimbi";
import { APICodes } from "../../Components/API";
import CloseIcon from "@mui/icons-material/Close";
import "./RicercaBinbi.css";
import { useSessionStorage } from "../../hooks/UseSessionStorage";
import { useLocation, useNavigate } from "react-router-dom";

const TIMEOUT_SECONS = 120;

const RicercaBimbi = () => {
  const [ricerca, setRicerca] = useState("");
  const [bambiniData, setBambiniData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState("");
  const refIframe = useRef(null);
  const [iframSrc, setIframeUrl] = useState("https://kdsoftware.it");
  const [ricercaNome, setRicercaNome] = useState("B");
  const [nomeBambino, setNomeBambino] = useSessionStorage("nome", "");
  const [timer, setTimer] = useState("00:00:00");
  const navigator = useNavigate();

  const location = useLocation();
  const { hash, pathname, search } = location;

// We need ref in this, because we are dealing
  // with JS setInterval to keep track of it and
  // stop it when needed
  const Ref = useRef(null);

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
// update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      setTimer(
        (hours > 9 ? hours : "0" + hours) +
          ":" +
          (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    } else {
      if (view !== "") {
        ChiudiLista();
        console.debug("chiudi lista");
      } else {
        if (pathname !== "media") {
          if (Ref.current) clearInterval(Ref.current);
          navigator("/media");

          console.log("media");
        }
      }
    }
  };

  const clearTimer = (e) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    setTimer('00:00:10');

// If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();

// This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + TIMEOUT_SECONS);
    return deadline;
  };

// We can use useEffect so that when the component
  // mount the timer will start as soon as possible

  // We put empty array to act as componentDid
  // mount only
  useEffect(() => {
    remoScrollbar();
    clearTimer(getDeadTime());
  }, []);

// Another way to call the clearTimer() to start
  // the countdown is via action event from the
  // button first we create function to be called
  // by the button
  const onClickReset = () => {
    clearTimer(getDeadTime());
  };

  const onClickSearch = () => {
    fetchRicerca();
  };

  const fetchRicerca = () => {
    setBambiniData([]);
    if (ricerca.trim().length > 0) {
      setLoading(true);
      setView("");
      setBambiniData([]);

      const options = {
        method: "GET",
        headers: {},
      };

      fetch(APICodes.CERCA + `?q=${ricerca}&n=${ricercaNome}`, options)
        .then((response) => response.json())
        .then((data) => {
          setLoading(false);
          setBambiniData(data.liste);
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
    }
  };

  const remoScrollbar= () =>{
    var sheet = document.createElement('style')
    sheet.innerHTML = "::-webkit-scrollbar {  display: none; }";
    document.body.appendChild(sheet);
  }
  useEffect(() => {
    onClickReset();
    const delayInputTimeoutId = setTimeout(() => {
      setRicerca(ricerca);
      fetchRicerca();
    }, 300);
    return () => clearTimeout(delayInputTimeoutId);
  }, [ricerca, ricercaNome, 300]);

  const ApriLista = (lista) => {
    onClickReset();
    setView(lista);

    setIframeUrl(`http://192.168.1.241/?c=${lista}&u=T`);
    //setIframeUrl(`http://localhost:3000/?c=${lista}&u=T`);

    let toScroll = setTimeout(() => {
      window.document.querySelector("#frame").addEventListener(
        "load",
        (e) => {
          e.target.contentWindow.addEventListener("scroll", (e) => {
            console.log("scrollin");

            onClickReset();
          });
        },
        1000
      );
    });
  };

  const ChiudiLista = () => {
    setView("");
    setBambiniData([]);
    setRicerca("");
    setNomeBambino("");
  };

  return (
    <div
      className="containerPrincipale totem"
      style={{ touchAction: "manipulation" }}
    >
      <div className="rotate-center square3"></div>
      <div className="rotate-center square4"></div>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "50px",
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <img src="/assets/img/Qlogo-removebg-preview.png" alt="" />
        </Grid>
      </Box>
      <div className="rotate-center square6"></div>
      <div className="rotate-center square5"></div>
      <img src="/assets/img/cloud_1.png" alt="" className="cloud1 slide" />

      <div style={{ marginTop: "-100px" }}>
        <div>
          <img
            src="/assets/img/Section_09.png"
            alt=""
            style={{ width: "100%", marginTop: "0px" }}
          />
        </div>
        <div
          style={{
            backgroundColor: "#feb8cc",
            width: "100%",
            height: "140px",
            marginTop: "-20px",
          }}
        >
          <Typography
            style={{
              textAlign: "center",
              fontSize: "90px",
              fontFamily: "nunito",
              fontWeight: 900,
              color: "#152a61",
              marginBottom: "40px",
              height: "100px",
              zIndex: 5000,
            }}
          >
            Cerca qui la tua lista.
          </Typography>
        </div>
        <div>
          <img
            src="/assets/img/Section_05.png"
            alt=""
            style={{ width: "100%", height: "100px", marginTop: "-20px" }}
          />
        </div>
        <div
          style={{
            width: "100%",
            zIndex: 10,
            marginTop: "-130px",
          }}
        >
          <img src="/assets/img/cloud_1.png" alt="" className="cloud2 slide2" />
        </div>
      </div>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginRight: { xs: "20px", sm: "60px" },
          marginBottom: { xs: "20px", sm: "30px" },
          marginTop: { xs: "20px", sm: "70px" },
          marginLeft: { xs: "20px", sm: "60px" },
          flexWrap: "wrap",
          "& > :not(style)": {
            m: 1,
            width: { xs: "100%", lg: "100%" },
            height: { xs: "100%", lg: "100%" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          },
        }}
      >
        {view === "" && (
          <Paper
            elevation={24}
            style={{
              backgroundColor: "white",
              padding: "5%",
            }}
          >
            <Grid
              container
              direction="column"
              justifyContent="space-around"
              alignItems="center"
            >
              <h1 style={{ fontSize: "60px" }}>Inserisci il nome</h1>
              <FormControl style={{ marginBottom: "20px" }}>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  defaultValue="B"
                >
                  <FormControlLabel
                    control={
                      <Radio
                        size="large"
                        onChange={(e) => setRicercaNome("B")}
                      />
                    }
                    label="Bambino"
                    value="B"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        size="large"
                        onChange={(e) => setRicercaNome("M")}
                      />
                    }
                    label="Madre"
                    value="M"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        size="large"
                        onChange={(e) => setRicercaNome("P")}
                      />
                    }
                    label="Padre"
                    value="P"
                  />
                </RadioGroup>
              </FormControl>

              <TextField
                id="outlined-basic"
                label="
              Digitare qui "
                variant="outlined"
                style={{ width: "40%" }}
                value={ricerca}
                onChange={(e) => setRicerca(e.target.value)}
              />

              <Button
                variant="contained"
                onClick={onClickSearch}
                style={{
                  marginTop: "30px",
                  width: "20%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <SearchIcon /> Ricerca
              </Button>
            </Grid>
          </Paper>
        )}

        {loading && <CircularProgress color="secondary" sx={{ mt: 2 }} />}

        {view === "" && !loading && (
          <Paper
            elevation={24}
            style={{
              backgroundColor: "white",
              padding: "5%",
            }}
          >
            <Grid
              container
              direction="row"
              spacing={5}
              gap={4}
              alignItems="center"
              justifyContent="space-around"
            >
              {bambiniData.length > 0 ? (
                bambiniData.map((bambino, index) => (
                  <ListaBimbi
                    key={index}
                    bambino={bambino}
                    ApriLista={(a) => {
                      console.log(bambino);
                      ApriLista(bambino.chiave);
                    }}
                  />
                ))
              ) : (
                <Typography
                  style={{
                    textAlign: "center",
                    fontSize: "60px",
                    fontFamily: "nunito",
                    fontWeight: 900,
                    zIndex: 0,
                  }}
                >
                  Non trovata
                </Typography>
              )}
            </Grid>
          </Paper>
        )}

        {view !== "" && !loading && (
          <Paper
            elevation={24}
            style={{
              backgroundColor: "white",
              padding: "2%",
              height: "80vh",
            }}
          >
            <button className="btnCard btnRicerca" onClick={ChiudiLista}>
              <CloseIcon /> Esci dalla lista
            </button>
            <iframe
              id="frame"
              title="Lista"
              ref={refIframe}
              src={iframSrc}
              frameBorder="0"
              style={{ border: "none", width: "100%", height: "100%" }}
            ></iframe>
          </Paper>
        )}
      </Box>

      <div className="ricercaFooter">
        <div>
          <img
            src="/assets/img/footer_bg.png"
            alt=""
            style={{
              width: "100%",
              marginTop: "20px",
              marginBottom: "-15px",
            }}
          />
        </div>
        <div
          style={{
            backgroundColor: "#2c4173",
            marginBottom: "-60px",
          }}
        >
          <img
            src="/footer_bg_3.jpg"
            alt=""
            style={{ width: "100%", height: "0px" }}
          />
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <h1
              style={{
                fontSize: "16px",
                color: "#b1bee0",
                marginBottom: "30px",
              }}
            >
              Powered by
            </h1>
            <img
              src="/assets/img/kd_logo.png"
              alt="Logo_KD_Software"
              className="logo_kd"
            />
          </Grid>
        </div>
        <div
          style={{
            backgroundColor: "#2c4173",
            width: "100%",
            height: "60px",
            marginBottom: "-20px",
          }}
        ></div>
      </div>

      <div
        style={{
          position: "fixed",
          left: "10px",
          bottom: "10px",
          color: "#2658AB",
        }}
      >
        {timer}
      </div>
    </div>
  );
};

export default RicercaBimbi;
