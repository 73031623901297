import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "theme-ui";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import ErrorTwoToneIcon from "@mui/icons-material/ErrorTwoTone";
import WarningTwoToneIcon from "@mui/icons-material/WarningTwoTone";
import NotificationImportantTwoToneIcon from "@mui/icons-material/NotificationImportantTwoTone";
import { useSessionStorage } from "../hooks/UseSessionStorage";

export const AlertSessionMessage = () => {
  const Ref = useRef(null);
  const [sessionMsg_, setSessionMsg_] = useSessionStorage("sessionMsg", {
    title: "",
    text: "",
    type: "",
  });
  const [sessionMsg, setSessionMsg] = useState({
    title: "",
    text: "",
    type: "",
  });
  const [time, setTime] = useState();

  useEffect(() => {
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      setTime(new Date());
      setSessionMsg(JSON.parse(window.sessionStorage.getItem("sessionMsg")));
    }, 1000);
    Ref.current = id;
  }, []);
  const OnClose = () => {
    setSessionMsg_({ title: "", text: "", type: "" });
    setSessionMsg({ title: "", text: "", type: "" });
  };

  function renderIcon(type) {
    switch (type) {
      case "S":
        return (
          <CheckCircleTwoToneIcon
            style={{ color: "green", marginRight: "4px" }}
            fontSize="large"
          />
        );
        break;
      case "W":
        return (
          <WarningTwoToneIcon
            style={{ color: "orange", marginRight: "4px" }}
            fontSize="large"
          />
        );
        break;
      case "E":
        return (
          <ErrorTwoToneIcon
            style={{ color: "red", marginRight: "4px" }}
            fontSize="large"
          />
        );
        break;

      default:
        return (
          <NotificationImportantTwoToneIcon
            style={{ color: "#F4D03F", marginRight: "4px" }}
            fontSize="large"
          />
        );
        break;
    }
  }

  if (sessionMsg === null || sessionMsg === undefined) {
    return <></>;
  }

  return (
    <Dialog
      open={
        sessionMsg !== null &&
        sessionMsg.text !== undefined &&
        sessionMsg.text !== ""
      }
      onClose={OnClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        <div style={{ display: "flex" }}>
          {renderIcon(sessionMsg.type)}{" "}
          <div style={{ marginTop: "3px" }}>{sessionMsg.title}</div>
        </div>
      </DialogTitle>
      <DialogContent>{sessionMsg.text}</DialogContent>
      <DialogActions>
        <Button autoFocus onClick={OnClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
