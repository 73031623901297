import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";

export const AlertSuccessDialog = ({ open, OnClose }) => {
  return (
    <Dialog
      open={open}
      onClose={OnClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        <div style={{ display: "flex" }}>
          <CheckCircleTwoToneIcon
            style={{ color: "green", marginRight: "4px" }}
            fontSize="large"
          />{" "}
          <div style={{ marginTop: "3px" }}>
            Acquisto effettuato con successo
          </div>
        </div>
      </DialogTitle>
      <DialogContent></DialogContent>
      <DialogActions>
        <Button autoFocus onClick={OnClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
