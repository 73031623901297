import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useSessionStorage } from "../hooks/UseSessionStorage";

const NomeBar = () => {
  const [nomeBambino, setNomeBambino] = useSessionStorage("nome", "");
  const [token, setToken] = useSessionStorage("token", undefined);

  return (
    <Box>
      <AppBar position="static">
        <Toolbar
          variant="dense"
          style={{ backgroundColor: "#ffc85b", color: "#12265a" }}
        >
          <Typography
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              padding: "15px",
            }}
            variant="h4"
            color="inherit"
            component="div"
          >
            <span
              style={{
                backgroundColor: "#ffc85b",
                color: "#12265a",
                fontWeight: "900",
              }}
            >
              {nomeBambino !== "" && `Questa è la lista per ${nomeBambino}`}
            </span>
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default NomeBar;
