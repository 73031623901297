import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Chip,
  Typography,
  styled,
} from "@mui/material";
import { Avatar } from "theme-ui";
import Grid from "@mui/material/Grid";
import { useSessionStorage } from "../../hooks/UseSessionStorage";
import { APICodes } from "../../Components/API";

const ResponsiveCardMedia = styled(CardMedia)({
  height: "200px",
  width: "200px",
  "@media (max-width: 600px)": {
    height: "200px",
    width: "50%",
  },
});

const CardArticoloGenitori = ({ product }) => {
  const [tipoUtente, setTipoUtente] = useSessionStorage("tipo", undefined);
  const [cassa, setCassa] = useSessionStorage("cassa", false);
  const [totem, setTotem] = useSessionStorage("totem", false);
  return (
    <>
      <Card style={{ marginTop: "10px", padding: "0 15px" }}>
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={4}
            justifyContent="center"
            alignItems="center"
          >
            <ResponsiveCardMedia
              component="img"
              image={`${APICodes.FOTO}${product.id_foto_lista}?size=m`}
              style={{
                margin: "auto",
                marginTop: "15px",
                marginBottom: "15px",
                height: "auto",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={10} lg={5}>
            <CardContent>
              <Typography variant="h6" component="div">
                {product.descrizione}
              </Typography>

              {product.prenotato === 1 && (
                <Chip
                  avatar={
                    <Avatar alt="Prenotato" src="/assets/img/reserved.png" />
                  }
                  label={product.rif_prenotato}
                  color="error"
                  style={{ marginBottom: "10px" }}
                />
              )}

              {(product.magazzino & cassa) === 1 && (
                <Chip
                  avatar={
                    <Avatar alt="In_Magazzino" src="/assets/img/storage.png" />
                  }
                  label="In Magazzino"
                  color="success"
                  style={{
                    marginLeft: "2px",
                    marginBottom: "10px",
                    marginTop: "10px",
                  }}
                />
              )}

              {(product.consegnato & cassa) === 1 && (
                <Chip
                  avatar={
                    <Avatar alt="Consegnato" src="/assets/img/shipped.png" />
                  }
                  label="Consegnato"
                  color="success"
                  style={{
                    marginLeft: "2px",
                    marginBottom: "10px",
                    marginTop: "10px",
                  }}
                />
              )}

              {(product.in_arrivo & cassa) === 1 && (
                <Chip
                  avatar={
                    <Avatar alt="In_Arrivo" src="/assets/img/truck.png" />
                  }
                  label="In Arrivo"
                  style={{
                    marginLeft: "2px",
                    marginBottom: "10px",
                    marginTop: "10px",
                    color: "white",
                    backgroundColor: "orange",
                  }}
                />
              )}
            </CardContent>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={3}>
            <Typography
              variant="body2"
              style={{
                fontWeight: "900",
                color: "black",
                fontSize: "15px",
                marginTop: "22px",
                fontFamily: "Nunito",
              }}
            >
              Prezzo: € {product.prz.toFixed(2)}
            </Typography>
            <Typography
              variant="body2"
              style={{
                fontWeight: "900",
                marginTop: "10px",
                marginBottom: "10px",
                color: "blue",
                fontSize: "20px",
              }}
            >
              {product.acquisto > 1 ? (
                <Chip
                  label="Acquistato"
                  variant="outlined"
                  style={{
                    backgroundColor: "#eb880e",
                    color: "white",
                    fontSize: "18px",
                  }}
                />
              ) : (
                <Chip
                  label="Non Acquistato"
                  variant="outlined"
                  style={{
                    backgroundColor: "#2c4173",
                    color: "white",
                    fontSize: "18px",
                  }}
                />
              )}
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default CardArticoloGenitori;
