import { Navigate } from "react-router-dom";
import { isExpired } from "react-jwt";
import { useSessionStorage } from "../hooks/UseSessionStorage";

const PrivateRoute = ({ children }) => {
  const [token, setToken] = useSessionStorage("token", undefined);

  function checkToken(t) {
    if (t === undefined || t === null || t === "") {
      return false;
    }
    const isMyTokenExpired = isExpired(t);
    if (isMyTokenExpired) {
      console.debug("TOKEN expired!");
      //setToken('');
      //return false;
    }
    return true;
  }

  if (!checkToken(token)) {
    return <Navigate to={"../"} />;
  }
  return children;
};

export default PrivateRoute;
