import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import WarningTwoToneIcon from "@mui/icons-material/WarningTwoTone";

export const AlertDialog = ({ open, OnClose, messaggio }) => {
  return (
    <Dialog
      open={open}
      onClose={OnClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        <div style={{ display: "flex" }}>
          <WarningTwoToneIcon
            style={{ color: "red", marginRight: "4px" }}
            fontSize="large"
          />{" "}
          <div style={{ marginTop: "3px" }}>Attenzione</div>
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{messaggio}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={OnClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
