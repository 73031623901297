import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Lista from "./Pagine/Lista/Lista";
import Login from "./Pagine/LoginPage/LoginPage";
import Carrello from "./Pagine/Carrello/Carrello";
import Dediche from "./Pagine/Dediche/Dediche";
import RicercaBimbi from "./Pagine/RicercaBimbi/RicercaBimbi";
import PrivateRoute from "./Components/PrivateRoute";
import { LogOutPage } from "./Pagine/LoginPage/LogOut";
import Media from "./Pagine/media/Media";
import { createContext, useState } from "react";
import { useSessionStorage } from "./hooks/UseSessionStorage";

export const UserContext = createContext(null);

function App() {
  const [cartCount, setCartCount] = useSessionStorage('00AS4',0);

  return (
    <UserContext.Provider value={{ cartCount: cartCount, setCartCount: setCartCount }}>
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/lista"
          element={
            <PrivateRoute>
              <Lista />
            </PrivateRoute>
          }
        />
        <Route
          path="/carrello"
          element={
            <PrivateRoute>
              <Carrello />
            </PrivateRoute>
          }
        />
        <Route
          path="/dediche"
          element={
            <PrivateRoute>
              <Dediche />
            </PrivateRoute>
          }
        />
        <Route path="/ricerca" element={<RicercaBimbi />} />
        <Route path="/logout" element={<LogOutPage />} />
        <Route path="/media" element={<Media />} />
      </Routes>
    </Router>
    </UserContext.Provider>
  );
}

export default App;
