import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useSessionStorage } from "../../hooks/UseSessionStorage";
import { APICodes } from "../../Components/API";
import { AlertDialog } from "../../Components/AlertDialog";
import { AlertSuccessDialog } from "../../Components/AlertSuccessDialog";
import { useNavigate } from "react-router-dom";
import LogOut from "../LoginPage/LogOut";
import { AlertSessionMessage } from "../../Components/AlertSessionMessage";

const Riepilogo = ({ datiTotaleCarrello, crt }) => {
  //const [tipoUtente, setTipoUtente] = useSessionStorage("tipo", undefined);
  const [cassa, setCassa] = useSessionStorage("cassa", false);
  //const [totem, setTotem] = useSessionStorage("totem", false);
  const [token, setToken] = useSessionStorage("token", undefined);
  //const [riepilogoData, setRiepilogoData] = useState([]);
  const [nomeBambino, setNomeBambino] = useSessionStorage("nome", "");
  const [fieldTel, setFieldTel] = useState("");
  const [fieldMail, setFieldMail] = useState("");
  const [fieldRif, setFieldRif] = useState("");
  const [fieldDedica, setFieldDedica] = useState("");
  const [fieldPrivacy, setFieldPrivacy] = useState(false);
  const [messaggioAlert, setMessaggioAlert] = useState("");
  const [pagamentoOK, setPagamentoOk] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  function pagamentoCassa() {
    if (ValidaCampi() === false) {
      return;
    }
    setLoading(true);
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body:
        '{"tel":"' +
        fieldTel +
        '","mail":"' +
        fieldMail +
        '","rif":"' +
        fieldRif +
        '","dedica":"' +
        fieldDedica +
        '"}',
    };

    fetch(APICodes.PAGAMENTO_CASSA, options)
      .then((response) => {
        setLoading(false);
        console.log(response);
        if (response.status != undefined && response.status === 200)
          return response.json();
        else return false;
      })
      .then((response) => {
        console.log(response);
        if (response) {
          setPagamentoOk(true);
        } else {
          alert("Errore richiesta server!");
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }

  function pagamentoCarta() {
    if (ValidaCampi() === false) {
      return;
    }

    setLoading(true);

    var fData = {
      tel: fieldTel,
      mail: fieldMail,
      rif: fieldRif,
      dedica: fieldDedica,
    };

    window.XPay_init(
      crt,
      String(datiTotaleCarrello.tot2),
      "Pagamento Liste Quattroelle",
      fData,
      () => {
        console.debug("OK");
        setLoading(false);
        setPagamentoOk(true);
      },
      () => {
        console.debug("ko");
        setLoading(false);
      }
    );
  }

  function pagamentoBonifico() {
    if (ValidaCampi() === false) {
      return;
    }
    setLoading(true);
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body:
        '{"tel":"' +
        fieldTel +
        '","mail":"' +
        fieldMail +
        '","rif":"' +
        fieldRif +
        '","dedica":"' +
        fieldDedica +
        '"}',
    };

    fetch(APICodes.PAGAMENTO_BONIFICO, options)
      .then((response) => {
        setLoading(false);
        if (response.status != undefined && response.status === 200)
          return response.json();
        else return false;
      })
      .then((response) => {
        console.log(response);
        if (response) {
          setPagamentoOk(true);
        } else {
          alert("Errore richiesta server!");
        }
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  }

  function ValidaCampi() {
    if (!cassa) {
      if (fieldMail === undefined || ValidateEmail(fieldMail)) {
        setMessaggioAlert(
          "E-mail obbligatoria, inserisci un indirizzo e-mail valido."
        );
        return false;
      }
      if (fieldTel == undefined || fieldTel == "" || fieldTel.length <= 6) {
        setMessaggioAlert(
          "Recapito telefonico obbligatorio, inserisci un numero di telefono valido."
        );
        return false;
      }
      if (fieldRif == undefined || fieldRif == "" || fieldRif.length <= 2) {
        setMessaggioAlert(
          "Inserisci un nome o i riferimenti di chi effettual il pagamento."
        );
        return false;
      }
      if (fieldPrivacy == undefined || !fieldPrivacy) {
        setMessaggioAlert(
          "Per poter procedere devi accettare i termini e condizioni di utilizzo e privacy."
        );
        return false;
      }
    }
    return true;
  }

  function ValidateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !re.test(email);
  }

  useEffect(() => {
    setFieldDedica('Tanti auguri '+nomeBambino);
    window.importJsNexi();
  }, []);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <AlertDialog
        open={messaggioAlert.length > 0}
        messaggio={messaggioAlert}
        OnClose={() => {
          setMessaggioAlert("");
        }}
      />
      <AlertSuccessDialog
        open={pagamentoOK}
        OnClose={() => {
          setPagamentoOk(false);
          LogOut();
        }}
      />
      <AlertSessionMessage />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginBottom: { xs: "20px", sm: "30px" },
          marginTop: { xs: "20px", sm: "20px" },
          marginRight: { xs: "20px", sm: "10vw", md: "22vw" },
          marginLeft: { xs: "20px", sm: "10vw", md: "22vw" },
          flexWrap: "wrap",
          "& > :not(style)": {
            m: 1,
            width: { xs: "100%", lg: "100%" },
            height: { xs: "100%", lg: "100%" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          },
        }}
      >
        <Paper elevation={24} style={{ marginBottom: "50px" }}>
          <div style={{ padding: "20px", textAlign: "center" }}>
            <Paper
              flex
              style={{ textAlign: "left", padding: "20px" }}
              elevation={10}
            >
              <Typography variant="h4" component="h4">
                Riepilogo
              </Typography>
              <Typography variant="h5" component="h5">
                Hai scelto {datiTotaleCarrello.righe} articoli.
              </Typography>
              <Typography variant="h5" component="h5">
                Totale carrello: {datiTotaleCarrello.tot} €
              </Typography>
            </Paper>
            <h1
              style={{
                marginTop: "50px",
                color: "#12265a",
                alignContent: "center",
              }}
            >
              Lascia una dedica
            </h1>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              {cassa === false && (
                <>
                  <h4
                    style={{
                      marginTop: "20px",
                      color: "#12265a",
                      display: "flex",
                    }}
                  >
                    La tua e-mail
                  </h4>
                  <TextField
                    id="outlined-basic"
                    label="Scrive la tua mail"
                    variant="outlined"
                    style={{ marginTop: "1px", width: "100%" }}
                    value={fieldMail}
                    onChange={(e) => setFieldMail(e.target.value)}
                  />
                  <h4
                    style={{
                      marginTop: "20px",
                      color: "#12265a",
                      display: "flex",
                    }}
                  >
                    Recapito telefonico
                  </h4>
                  <TextField
                    id="outlined-basic"
                    label="Telefono"
                    variant="outlined"
                    style={{ marginTop: "1px", width: "100%" }}
                    value={fieldTel}
                    onChange={(e) => setFieldTel(e.target.value)}
                  />
                </>
              )}

              <h4
                style={{ marginTop: "20px", color: "#12265a", display: "flex" }}
              >
                Lo Regala
              </h4>
              <TextField
                id="outlined-multiline-statics"
                label="Lascia qui il tuo nome"
                rows={2}
                style={{ marginTop: "1px", width: "100%", height: "" }}
                value={fieldRif}
                onChange={(e) => setFieldRif(e.target.value)}
              />
              <h4
                style={{ marginTop: "20px", color: "#12265a", display: "flex" }}
              >
                Testo della dedica
              </h4>
              <TextField
                id="outlined-multiline-statics"
                label="Scrivi qui la tua dedica"
                multiline
                rows={4}
                style={{ marginTop: "1px", width: "100%" }}
                value={fieldDedica}
                onChange={(e) => setFieldDedica(e.target.value)}
              />
              <FormControlLabel
                required
                control={
                  <Switch
                    checked={fieldPrivacy}
                    onChange={(e) => setFieldPrivacy(e.target.checked)}
                  />
                }
                label="Acconsento al trattamento dei miei dati personali dell’ informativa privacy di seguito riportata (obbligatorio)."
                style={{ marginTop: "20px" }}
              />
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                {cassa === true ? (
                  <Grid>
                    <Button
                      variant="contained"
                      style={{
                        marginTop: "40px",
                        marginBottom: "15px",
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        backgroundColor: "#fece7e",
                        color: "#12265a",
                        fontWeight: "900",
                        height: "50px",
                      }}
                      onClick={pagamentoCassa}
                    >
                      Paga in cassa
                    </Button>
                    <Button
                      variant="contained"
                      style={{
                        marginBottom: "15px",
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        backgroundColor: "#fece7e",
                        color: "#12265a",
                        fontWeight: "900",
                        height: "50px",
                      }}
                      onClick={pagamentoBonifico}
                    >
                      Paga con Bonifico Bancario
                    </Button>
                  </Grid>
                ) : (
                  <Button
                    variant="contained"
                    style={{
                      marginTop: "40px",
                      marginBottom: "15px",
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      backgroundColor: "#eb880e",
                      color: "#12265a",
                      fontWeight: "900",
                      height: "50px",
                    }}
                    onClick={pagamentoCarta}
                  >
                    Paga con carta di Credito/Debito
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Paper>
      </Box>
    </>
  );
};

export default Riepilogo;
