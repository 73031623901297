import {
  Card,
  CardContent,
  CardMedia,
  Chip,
  Typography,
  Button,
  Avatar,
  styled,
} from "@mui/material";
import React, { useContext, useState } from "react";
import Grid from "@mui/material/Grid";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import CheckIcon from "@mui/icons-material/Check";
import { useSessionStorage } from "../../hooks/UseSessionStorage";
import { APICodes } from "../../Components/API";
import useQuery from "../../hooks/UseQuery";
import { UserContext } from "../../App";

const ResponsiveCardMedia = styled(CardMedia)({
  height: "200px",
  width: "200px",
  "@media (max-width: 600px)": {
    height: "200px",
    width: "50%",
  },
});

const CardArticoloAcquisto = ({ product, tipoLista, OnAggiungiAlCarrello }) => {
  let query = useQuery();
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [isButtonIconChanged, setIsButtonIconChanged] = useState(false);
  const [tipoUtente, setTipoUtente] = useSessionStorage("tipo", undefined);
  const [cassa, setCassa] = useSessionStorage("cassa", false);
  const [totem, setTotem] = useSessionStorage("totem", false);
  const [token, setToken] = useSessionStorage("token", undefined);
  const { cartCount, setCartCount } = useContext(UserContext);

  const buttonColor =
    isButtonClicked || product.acquisto !== 0 ? "#2c4173" : "green";
  const buttonText =
    isButtonClicked || product.acquisto !== 0
      ? "Aggiunto al carrello"
      : "Aggiungi al Carrello";
  const buttonIcon = isButtonIconChanged ? (
    <CheckIcon style={{ marginRight: "5px" }} />
  ) : (
    <AddShoppingCartIcon style={{ marginRight: "5px" }} />
  );

  const handleButtonClick = () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body:
        '{"riga":' +
        product.riga +
        ',"codice":"' +
        product.codice +
        '","prz":' +
        product.prz +
        ',"qta":' +
        product.qta +
        "}",
    };

    fetch(APICodes.CARRELLO, options)
      .then((response) => {
        if (response.status != undefined && response.status === 200)
          return response.json();
        else return false;
      })
      .then((response) => {
        if (response) {
          setIsButtonClicked(!isButtonClicked);
          setIsButtonIconChanged(!isButtonIconChanged);
          setCartCount(cartCount+1);
          OnAggiungiAlCarrello();
        } else {
          alert("Errore richiesta server");
        }
      })
      .catch((err) => console.error(err));
  };

  if (product.placeholder === 1) {
    return (
      <Card style={{ marginTop: "10px", padding: "0 15px" }}>
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={4}
            justifyContent="center"
            alignItems="center"
          >
            <ResponsiveCardMedia
              component="img"
              image={`${APICodes.FOTO}${product.id_foto_lista}?size=s`}
              style={{
                margin: "auto",
                marginTop: "15px",
                marginBottom: "15px",
                height: "auto",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={10} lg={5}>
            <CardContent>
              <Typography variant="h6" component="div">
                {product.descrizione}
              </Typography>
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    );
  }

  const productVisible = () =>{
    if(cassa){
      if(product.acquisto === 0 && product.prenotato === 1){
        return true
      }
    }
    if(product.prenotato !== 0){
      return false;
    }
    if(product.acquisto !== 0){
      if(product.acquisto === 1 && product.carrello === 1){
        return true
      }
      return false;
    }



    return true;
  }

  return (
    <>
      {/*((product.acquisto === 0 && product.prenotato === 1 && cassa) ||
        (product.acquisto === 1 && product.carrello === 1) ||
        product.acquisto === 0 ||
        (product.prenotato === 0 && totem === 1)) */
        (productVisible()) && (
        <Card style={{ marginTop: "10px", padding: "0 15px" }}>
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={4}
              justifyContent="center"
              alignItems="center"
            >
              <ResponsiveCardMedia
                component="img"
                image={`${APICodes.FOTO}${product.id_foto_lista}?size=s`}
                style={{
                  margin: "auto",
                  marginTop: "10px",
                  marginBottom: "10px",
                  height: "auto",
                }}
              />
            </Grid>

            {/* Chip */}
            <Grid item xs={12} sm={12} md={10} lg={5}>
              <CardContent>
                <Typography variant="h6" component="div">
                  {product.descrizione}
                </Typography>

                {!totem &&
                  product.acquisto === 0 &&
                  product.prenotato === 1 &&
                  cassa && (
                    <Chip
                    avatar={
                      <Avatar alt="Prenotato" src="/assets/img/reserved.png" style={{
                        marginRight:'-20px'
                      }}/>
                    }
                    title={product.rif_prenotato}
                    color="error"
                    style={{ marginBottom: "10px", width:'40px' }}
                  />
                  )}

                {((product.magazzino === 1 && cassa) ||
                  (product.magazzino === 1 && totem)) && (
                  <Chip
                    avatar={
                      <Avatar
                        alt="In_Magazzino"
                        src="/assets/img/storage.png"
                        style={{
                          marginLeft: "42%",
                        }}
                      />
                    }
                    color="primary"
                    style={{
                      marginBottom: "20px",
                      marginTop: "20px",
                    }}
                  />
                )}

                {product.consegnato === 1 && cassa && (
                  <Chip
                    avatar={
                      <Avatar
                        alt="Consegnato"
                        src="/assets/img/shipped.png"
                        style={{
                          marginLeft: "45%",
                        }}
                      />
                    }
                    color="success"
                    style={{
                      marginBottom: "20px",
                      marginTop: "20px",
                    }}
                  />
                )}

                {((product.in_arrivo === 1 && cassa) ||
                  (product.in_arrivo === 1 && totem)) && (
                  <Chip
                    avatar={
                      <Avatar
                        alt="In_Arrivo"
                        src="/assets/img/truck.png"
                        style={{
                          marginLeft: "45%",
                        }}
                      />
                    }                 
                    style={{
                      marginBottom: "20px",
                      marginTop: "20px",
                      color: "white",
                      backgroundColor: "orange",
                    }}
                  />
                )}
              </CardContent>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={3}>
              {!totem && (
                <Button
                  variant="contained"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                    fontWeight: "900",
                    backgroundColor: buttonColor,
                    color: "white",
                  }}
                  onClick={handleButtonClick}
                  disabled={isButtonClicked || product.acquisto !== 0}
                >
                  {buttonIcon} {buttonText}
                </Button>
              )}
              <Typography
                variant="body2"
                style={{
                  fontWeight: "900",
                  color: "black",
                  fontSize: "17px",
                  marginBottom: "10px",
                  marginTop: "10px",
                  fontFamily: "Nunito",
                }}
              >
                Prezzo: € {product.prz.toFixed(2)}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      )}
    </>
  );
};

export default CardArticoloAcquisto;
