const APIPath = process.env.REACT_APP_API_PATH;

const APIVersion = "api/v1/";
export const APICodes = {
  LOGIN: `${process.env.REACT_APP_API_LPATH}`,
  CERCA: `${APIPath}${APIVersion}liste/cerca`,
  DEDICHE: `${APIPath}${APIVersion}liste/dediche`,
  FOTO: `${APIPath}${APIVersion}liste/foto/`,
  LISTE: `${APIPath}${APIVersion}liste`,
  TOTALE_CARRELLO: `${APIPath}${APIVersion}cart/totals`,
  CARRELLO: `${APIPath}${APIVersion}cart/articoli`,
  CART: `${APIPath}${APIVersion}cart/`,
  PAGAMENTO_CASSA: `${APIPath}${APIVersion}cart/pay/cs`,
  PAGAMENTO_BONIFICO: `${APIPath}${APIVersion}cart/pay/bb`,
  CONSEGNA: `${APIPath}${APIVersion}cart/articoli/consegna`,
  MEDIA: `${APIPath}${APIVersion}totem/media`,
};
