import { Alert, Box, CircularProgress, Paper, Backdrop } from "@mui/material";
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import "./LoginPage.css";
import { useSessionStorage } from "../../hooks/UseSessionStorage";
import { APICodes } from "../../Components/API";
import { useNavigate } from "react-router-dom";
import useQuery from "../../hooks/UseQuery";

const LoginPage = () => {
  let query = useQuery();
  const [allert, setAllert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allertTxt, setAllertTxt] = useState("");
  const [codice, setCodice] = useState("");
  const [token, setToken] = useSessionStorage("token", undefined);
  const [tipoUtente, setTipoUtente] = useSessionStorage("tipo", undefined); //tipo lista gesnitori paranti, sostituire con LISTA_JSON.lista.tipo
  const [cassa, setCassa] = useSessionStorage("cassa", false);
  const [totem, setTotem] = useSessionStorage("totem", false);
  const [nomeBambino, setNomeBambino] = useSessionStorage("nome", "");
  const navigate = useNavigate();

  const MostraAllert = (msg) => {
    setAllertTxt(msg);
    setAllert(true);
    setLoading(false);
  };

  function parseJWT(_token) {
    var base64Url = _token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  function verifyToken(token) {
    if (token == "") {
      return false;
    }

    const JWT = parseJWT(token);

    if (JWT.exp == "") {
      return false;
    }

    if (JWT.exp < (new Date().getTime() + 1) / 1000) {
      return false;
    } else {
      return true;
    }
  }

  const AccediClick = (c) => {
    let _codice = codice;
    if (typeof c === "string" && c !== "") {
      _codice = c;
    }
    if (_codice.trim().length != 8) {
      MostraAllert("Il codice inserito non è valido!");
      return;
    }

    setLoading(true);

    const options = {
      method: "GET",
      headers: {
        jwtusername: "4lWeb",
        jwtpassword: _codice,
      },
    };

    fetch(APICodes.LOGIN, options)
      .then((response) => {
        setLoading(false);
        return response.json();
      })
      .then((data) => {
        console.debug(data);
        setAllert(false);
        if (data.token != undefined) {
          setToken(data.token);
        }
        if (verifyToken(data.token)) {
          setTipoUtente(parseJWT(data.token).TIPO);
          setNomeBambino(parseJWT(data.token).BAMBINO);
          navigate("/lista");
          //sUser.loggedAt = Date();
        } else {
          //TODO: ERRORE token non valido
          console.error("ERRORE token non valido");
          MostraAllert("Il codice inserito non è corretto!");
        }
      })
      .catch((err) => {
        MostraAllert("Accesso non riuscito, verifica il codice inserito!");
        console.error(err);
      });
  };

  const CodiceChange = (e) => {
    let cod = e.target.value.trim().toUpperCase();
    setCodice(cod);
    if (cod.trim().length != 8) {
      MostraAllert("Il codice inserito non è valido!");
      return;
    } else {
      setAllert(false);
    }
  };

  useEffect(() => {
    if (
      typeof query.get("c") === "string" &&
      query.get("c").trim() !== "" &&
      typeof query.get("u") === "string" &&
      query.get("u").trim() === "Q"
    ) {
      setCassa(true);
      setTotem(false);
      setCodice(query.get("c"));
      AccediClick(query.get("c"));
      console.debug("cassa");
    } else if (
      typeof query.get("c") === "string" &&
      query.get("c").trim() !== "" &&
      typeof query.get("u") === "string" &&
      query.get("u").trim() === "T"
    ) {
      setCodice(query.get("c"));
      setTotem(true);
      setCassa(false);
      AccediClick(query.get("c"));

      console.debug("totem");
    } else {
      setCassa(false);
      setTotem(false);
    }
  }, []);

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        sx={{
          display: totem ? "none" : "flex",
          justifyContent: "center",
          marginX: { xs: "0px", sm: "auto" },
          flexWrap: "wrap",
          "& > :not(style)": {
            m: 1,
            width: { xs: "100%", sm: "50%", md: "40%", lg: "30%" },
            height: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          },
        }}
      >
        <Paper elevation={24}>
          <div style={{ padding: "20px", textAlign: "center" }}>
            <img
              src="/assets/img/Qlogo.png"
              alt="Logo da sua empresa"
              style={{ maxWidth: "100%", height: "auto" }}
            />
            <h1 style={{ marginTop: "50px", color: "#12265a" }}>
              Inserisci il codice
            </h1>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                id="outlined-basic"
                label="Codice Lista"
                inputProps={{ maxLength: 8, style: { textAlign: "center" } }}
                required={true}
                variant="outlined"
                style={{ marginTop: "20px", width: "90%" }}
                value={codice}
                onChange={CodiceChange}
              />
              {allert && (
                <Alert variant="outlined" severity="warning" sx={{ mt: 2 }}>
                  {allertTxt}
                </Alert>
              )}

              <Button
                variant="contained"
                onClick={AccediClick}
                style={{
                  marginTop: "40px",
                  width: "60%",
                  backgroundColor: "#fece7e",
                  color: "#12265a",
                  fontWeight: "900",
                  height: "50px",
                }}
              >
                Accedi
              </Button>
            </div>
          </div>
          <div
            style={{
              padding: "20px",
              textAlign: "center",
              marginBottom: "5px",
            }}
          >
            <h5>
              © Copyright 2023 -{" "}
              <a href="https://www.quattroelle.eu" target="_blank">
                Quattroelle
              </a>
            </h5>
            <h6>
              Developed by
              <a href="https://www.kdsoftware.it" target="_blank">
                {" "}
                KD Software
              </a>
            </h6>
          </div>
        </Paper>
      </Box>
    </div>
  );
};

export default LoginPage;
