import React, { useEffect, useState } from "react";
import NavBar from "../../Components/NavBar";
import Riepilogo from "./Riepilogo";
import CardCarrello from "./CardCarrello";
import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import { useSessionStorage } from "../../hooks/UseSessionStorage";
import { APICodes } from "../../Components/API";
import { Link } from "react-router-dom";

const Carrello = () => {
  const [riepilogoData, setRiepilogoData] = useState({});
  const [token, setToken] = useSessionStorage("token", undefined);
  const [loading, setLoading] = useState(false);

  const fetchCarrello = () => {
    setLoading(true);
    const options = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    fetch(APICodes.CARRELLO, options)
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        setRiepilogoData(data);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const cardRiepilogo = () => {
    const options = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    fetch(APICodes.CARRELLO, options)
      .then((response) => response.json())
      .then((data) => {
        setRiepilogoData(data.totals);
      })
      .catch((err) => console.error(err));
  };

  const renderNoContent = () => {
    if (!loading) {
      return (
        <>
          <Typography
            variant="h5"
            style={{
              color: "#12265a",
              fontWeight: "900",
              marginTop: "250px",
              marginBottom: "-170px",
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            Nessun articolo aggiunto al carrello
          </Typography>
          <Link to={"/lista"} style={{ textDecoration: "none" }}>
            <div
              style={{
                marginTop: "250px",
                marginBottom: "-170px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button className="btn_Ritorno">Vai alla lista</button>
            </div>
          </Link>
        </>
      );
    }
  };

  const onRefreshCarrello = () => {
    fetchCarrello();
  };

  useEffect(() => {
    fetchCarrello();
  }, []);

  return (
    <>
      <NavBar />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <Typography
          variant="h4"
          style={{
            color: "#12265a",
            fontWeight: "900",
            marginTop: "250px",
            marginBottom: "-70px",
            fontFamily: "Nunito",
          }}
          className="TituloCarrello"
        >
          Carrello
        </Typography>
      </Box>
      {riepilogoData.cart != undefined && riepilogoData.cart.length >= 1 ? (
        <div className="containerPrinc">
          <CardCarrello
            infoCarrello={riepilogoData.cart}
            onRefresh={onRefreshCarrello}
          />
          <Riepilogo
            datiTotaleCarrello={riepilogoData.totals}
            crt={riepilogoData.totals.crt}
          />
        </div>
      ) : (
        renderNoContent()
      )}
    </>
  );
};

export default Carrello;
