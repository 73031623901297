import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Typography,
  Grid,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSessionStorage } from "../../hooks/UseSessionStorage";
import { APICodes } from "../../Components/API";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../App";

const ResponsiveImage = (props) => (
  <img
    {...props}
    style={{
      height: "200px",
      width: "200px",
      "@media (max-width: 600px)": {
        height: "100px",
        width: "100px",
      },
    }}
    alt="Illustrazione del prodotto"
  />
);

const CardCarrello = ({ infoCarrello, onRefresh }) => {
  const [tipoUtente, setTipoUtente] = useSessionStorage("tipo", undefined);
  const [cassa, setCassa] = useSessionStorage("cassa", false);
  const [totem, setTotem] = useSessionStorage("totem", false);
  const [token, setToken] = useSessionStorage("token", undefined);
  const navigate = useNavigate();
  const { cartCount, setCartCount } = useContext(UserContext);

  const eliminaArticoli = (riga, codice) => {
  
    const options = {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: '{"riga":' + riga + ',"codice":"' + codice + '"}',
    };

    fetch(APICodes.CARRELLO, options)
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setCartCount(cartCount-1);
        onRefresh();
        window.location.reload();
      })
      .catch((err) => console.error(err));
  };

  const fetchConsegna = (riga, consegnato) => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: '{"riga":' + riga + ',"consegnato":"' + consegnato + '"}',
    };

    fetch(APICodes.CONSEGNA, options)
      .then((response) => {
        console.log(response);
        if (response.status != undefined && response.status === 200)
          return response.json();
        else return false;
      })
      .then((response) => {
        if (response) {
          onRefresh();
        } else {
          alert("Errore richiesta server!");
        }
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    console.log(infoCarrello);
  }, []);

  return (
    <>
      {infoCarrello.map((cartItem, index) => (
        <Card
          sx={{
            marginTop: { xs: "20px", md: "22px", sm: "30px" },
            marginRight: { xs: "20px", sm: "10vw", md: "22vw" },
            marginLeft: { xs: "20px", sm: "10vw", md: "22vw" },
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "center" },
          }}
        >
          <Grid
            container
            spacing={2}
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "20px",
              margin: "10px",
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              xs={12}
              sm={6}
              md={3}
            >
              <ResponsiveImage
                src={`${APICodes.FOTO}${cartItem.id_foto_lista}?size=s`}
                alt={cartItem.descrizione}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CardContent>
                <Typography variant="h6" component="div">
                  {cartItem.descrizione}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  style={{
                    fontWeight: "900",
                    fontSize: "20px",
                    color: "black",
                  }}
                >
                  Prezzo: € {cartItem.prz}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  style={{ fontWeight: "900", fontSize: "15px" }}
                >
                  Quantità: {cartItem.qta}
                </Typography>
                {cassa && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => {
                          fetchConsegna(cartItem.riga, e.target.checked);
                        }}
                        checked={cartItem.consegna === 1}
                      />
                    }
                    label="Consegnato"
                  />
                )}
              </CardContent>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="error"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "40px",
                  color: "red",
                  fontWeight: "900",
                  height: "40px",
                }}
                onClick={() => {
                  console.log(cartItem);
                  eliminaArticoli(cartItem.riga, cartItem.codice);
                }}
              >
                <DeleteIcon /> Rimuovi
              </Button>
            </Grid>
          </Grid>
        </Card>
      ))}
    </>
  );
};

export default CardCarrello;
