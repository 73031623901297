import React, { useEffect, useState } from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { useSessionStorage } from "../../hooks/UseSessionStorage";
import { APICodes } from "../../Components/API";

const CardDedica = ({ dedica }) => {
  const [dettaglio, setDettaglio] = useState([]);
  const [token, setToken] = useSessionStorage("token", undefined);

  const FetchDettaglio = () => {
    console.log(dedica.id);
    const options = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    fetch(APICodes.DEDICHE + "/" + dedica.id, options)
      .then((response) => response.json())
      .then((data) => {
        setDettaglio(data.dettaglio);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    FetchDettaglio();
  }, []);

  const sanitize = (s) => {
    return String(s).replaceAll(",", ", ").replaceAll("  ", " ");
  };
  return (
    <Card
      style={{
        margin: "12px",
        padding: "25px",
        maxWidth: "800px",
        width: "80%",
      }}
      sx={{ width: { lg: "100%", md: "100%" } }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
      >
        <CardContent>
          <div style={{ marginBottom: "15px", color: "black" }}>
            <Typography
              variant="body2"
              color="text.secondary"
              style={{ fontSize: "15px" }}
            >
              {dedica.data_pagamento}
            </Typography>
            <Typography
              variant="h6"
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                wordBreak: "break-word",
              }}
            >
              {sanitize(dedica.riferimenti)}
            </Typography>
          </div>
          <Typography
            variant="body1"
            color="text.primary"
            style={{
              marginBottom: "20px",
            }}
          >
            Dedica: {dedica.dedica}
          </Typography>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="left"
            alignItems="left"
          >
            {dettaglio.map((articolo, index) => (
              <Grid key={index}>
                <div className="mapDettaglio">
                  {articolo.descrizione.replace(/\s{2,10}/g, "")}
                </div>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Grid>
    </Card>
  );
};

export default CardDedica;
