import { useEffect } from "react";
import { APICodes } from "../../Components/API";
import { Typography, Box } from "@mui/material";
import NomeBar from "../../Components/NomeBar";
import { useSessionStorage } from "../../hooks/UseSessionStorage";

function LogOut(eliminaCarrello) {
  if(eliminaCarrello){
  let token = JSON.parse(window.sessionStorage.getItem("token"));
  let bearer = "Bearer " + token;
  const options = {
    method: "DELETE",
    headers: {
      Authorization: bearer,
    },
  };
  fetch(APICodes.CART, options)
    .then((response) => {
      if (response.status != undefined && response.status === 200)
        return response.json();
      else return false;
    })
    .then((response) => {
      if (response) {
        window.sessionStorage.removeItem("token");
        window.sessionStorage.removeItem("tipo");
        window.sessionStorage.removeItem("nome");
        window.sessionStorage.removeItem("sessionMsg");
        window.location.replace("/");
      }
    })
    .catch((err) => {
      console.error(err);
      window.sessionStorage.removeItem("token");
      window.sessionStorage.removeItem("tipo");
      window.sessionStorage.removeItem("nome");
      window.sessionStorage.removeItem("sessionMsg");
      window.location.replace("/");
    });
  }else{
    window.sessionStorage.removeItem("token");
    window.sessionStorage.removeItem("tipo");
    window.sessionStorage.removeItem("nome");
    window.sessionStorage.removeItem("sessionMsg");
    window.location.replace("/");
  }
}

export const LogOutPage = () => {
  const [tipoUtente, setTipoUtente] = useSessionStorage("tipo", undefined);
  useEffect(() => {
    LogOut(tipoUtente==="P");
    setTimeout(() => {
      window.location.replace("/");
    }, 5000);
  }, []);

  return (
    <>
      <NomeBar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <Typography
          variant="h4"
          className="Titulo"
          style={{ fontFamily: "Nunito", marginTop: "50px", color: "#12265a" }}
        >
          Arrivederci da Quattroelle!
        </Typography>
      </Box>
    </>
  );
};

export default LogOut;
